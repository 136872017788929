import { createWebHistory, createRouter } from "vue-router"

const router = new createRouter({
    history: createWebHistory(),
    base: process.env.BASE_URL,
    routes: [
        {
            path: "/",
            name: "index",
            component: () => import(/* webpackPrefetch: true */ "../views/index/index"),
            meta: {
                layout: "layout-default",
                isWhite: true,
            },
        },
        {
            path: "/questions",
            name: "questions",
            component: () =>
                import(
                    /* webpackChunkName: "questions" */ "../views/questions/questions"
                ),
        },
        {
            path: "/ask",
            name: "ask",
            component: () => import(/* webpackChunkName: "ask",  webpackPrefetch: false */"../views/askForm/AskForm")
        },
        {
            path: "/askResult/:result",
            name: "askResult",
            component: () => import(/* webpackChunkName: "askResult",  webpackPrefetch: false */"../views/askForm/AskResult"),
            props: true
        },
        {
            path: "/company",
            name: "company",
            component: () =>
                import(
                    /* webpackChunkName: "company" */ "../views/company/company"
                ),
        },
        {
            path: "/data-pay",
            name: "data-pay",
            component: () => import(/* webpackChunkName: "data-pay",  webpackPrefetch: false */"../views/data-pay")
        },
        {
            path: "/data-pay-qr",
            name: "data-pay-qr",
            component: () => import(/* webpackChunkName: "data-pay-qr",  webpackPrefetch: false */"../views/data-pay-qr")
        },
        {
            path: "/view-document/:name",
            name: "view-document",
            component: () => import(/* webpackChunkName: "html-file",  webpackPrefetch: false */"../views/html-file/html-file")
        },
        {
            name: "showcase",
            path: "/showcase",
            component: () =>
                import(
                /* webpackChunkName: "showcase" */ "@/views/combacker/combacker"
                ),
            meta: {
                layout: "layout-full_screen",
            },
        },
        {
            path: "/404",
            name: "404",
            component: () =>
                import(
                    /* webpackChunkName: "contact-view" */ "@frontend/dabank-main/views/404/404"
                ),
        },
    ],
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
});


export default router;
