<!-- eslint-disable no-undef -->
<template>
    <div class="layout-default">
        <div class="layout-max1200__wrapper">
            <Header
                v-model="isOpenMenu"
                class="layout-max1200"
                :links="{
                    anketa: linkToAnketa,
                    landing: linkToLanding,
                    lk: linkToLK,
                }"
                :is-login="false"
            />
        </div>
        <main
            v-show="!isOpenMenu"
            class="layout-default__main"
        >
            <slot />
        </main>
        <div
            v-show="!isOpenMenu"
            :class="['layout-max1200__wrapper', {
                '_bgwhite': $route.meta.isWhite
            }]"
        >
            <Footer class="layout-max1200" />
        </div>
    </div>
</template>

<script>
import { defineAsyncComponent } from "vue"
import Header from "@frontend/dabank-main/components/header/header.vue";

export default {
    name: "LayoutDefault",
    components: {
        Header,
        Footer: defineAsyncComponent(() => import( "@/components/footer/footer.vue"))
    },
    data() {
        return {
            isOpenMenu: false,
        };
    },
    computed: {
        linkToAnketa() {
            // eslint-disable-next-line no-undef
            return env.VUE_APP_ANKETA_URL;
        },
        linkToLanding() {
            // eslint-disable-next-line no-undef
            return env.VUE_APP_LANDING_URL;
        },
        linkToLK() {
            // eslint-disable-next-line no-undef
            return env.VUE_APP_LK_URL;
        },
    },
};
</script>

<style>
.layout-default {
  border-top: 16px solid #00ff57;
}

.layout-default__main {
  overflow-x: hidden;
}

@media (max-width: 768px) {
  .layout-default__main {
    overflow-x: visible;
  }
}
</style>